<template>
  <Head v-if="costMin">
    <title>
      Купить квартиру-студию в новостройке на Ботаническом саду в Москве от
      застройщика | ЖК VERY
    </title>
    <meta
      name="description"
      :content="
        'Продажа новых однокомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:title"
      content="Купить квартиру-студию в новостройке на Ботаническом саду в Москве от застройщика | ЖК VERY"
    />
    <meta
      property="og:description"
      :content="
        'Продажа новых однокомнатных квартир в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>

  <FlatsSearch
    h1="Однокомнатные квартиры"
    pre-rooms="1"
    @costMin="onCostMinEmits"
  >
    <template #text>
      <div class="text mt-5">
        <p>
          Хотите наслаждаться комфортной жизнью в&nbsp;сердце большого города,
          но&nbsp;в&nbsp;окружении зеленых парков? Решили приобрести себе
          и&nbsp;своей второй половинке комфортное жилье? Рассматриваете
          выгодные варианты для инвестирования?
        </p>
        <p>
          Как насчет 1-комнатной квартиры у&nbsp;Ботанического сада?
          ЖК&nbsp;Very в&nbsp;Марфино&nbsp;&mdash; это комплекс нового формата,
          в&nbsp;котором органично сочетается комфорт жизни в&nbsp;крупном
          мегаполисе со&nbsp;свежим воздухом и&nbsp;открытыми пространствами
          Подмосковья.
        </p>
        <p>
          Вы&nbsp;можете купить однокомнатную квартиру у&nbsp;Ботанического сада
          в&nbsp;формате студии. Чем такой формат лучше классических
          &laquo;однушек&raquo;, построенных в&nbsp;середине и&nbsp;конце
          прошлого века? Давайте разбираться.
        </p>
        <h2 class="h4">Особенности планировки квартир</h2>
        <p>
          1-комнатные квартиры по&nbsp;ул. Ботаническая в&nbsp;ЖК&nbsp;Very
          имеют оптимальную для проживания одного человека или пары площадь.
          Благодаря формату студии меньший метраж не&nbsp;противоречит концепции
          открытости и&nbsp;свободных пространств, которая использовалась при
          проектировании жилого комплекса. Стоимость квартир делает
          их&nbsp;доступными для покупателя, который хочет приобрести
          ее&nbsp;себе или с&nbsp;целью последующей сдачи в&nbsp;аренду.
        </p>
        <p>
          Площадь предлагаемых нами квартир начинается с&nbsp;23&nbsp;м2. Если
          сравнивать ее&nbsp;с&nbsp;площадью классических однокомнатных квартир,
          то&nbsp;она может показаться недостаточной. Но&nbsp;грамотная
          планировка позволяет комфортно чувствовать себя в&nbsp;ней одному
          человеку или паре. Любители простора найдут в&nbsp;каталоге доступных
          для продажи квартир и&nbsp;более просторные варианты.
        </p>
        <h2 class="h4">
          Что получает покупатель 1-комнатной квартиры вместе с&nbsp;жильем
        </h2>
        <p>
          Покупка недвижимости в&nbsp;ЖК&nbsp;Very&nbsp;&mdash; это нечто
          большее, чем приобретение обычного жилья в&nbsp;Марфино. Вместе
          с&nbsp;уютным домом покупатель получает в&nbsp;свое пользование
          развитую инфраструктуру комплекса.
        </p>
        <p>В&nbsp;том числе:</p>
        <ol>
          <li>
            Инфраструктура для спорта. Жители комплекса могут поддерживать себя
            в&nbsp;отличной физической форме, а&nbsp;также перезагружать
            сознание после тяжелого трудового дня. Для этого на&nbsp;территории
            предусмотрены спортивные площадки, а&nbsp;также собственный
            спортивный комплекс с&nbsp;бассейном. Спортивная инфраструктура
            включает в&nbsp;себя волейбольную площадку, тренажерную площадку,
            крытый зал для игры в&nbsp;футбол.
          </li>
          <li>
            Инфраструктура для детей. Подрастающие жители жилого комплекса
            смогут приятно проводить время на&nbsp;специально оборудованных
            площадках с&nbsp;амортизирующим покрытием и&nbsp;защитой
            от&nbsp;солнца. Им&nbsp;особенно понравятся площадки
            с&nbsp;альпинистскими приспособлениями и&nbsp;место для игры
            с&nbsp;песком и&nbsp;водой у&nbsp;искусственного водоема.
          </li>
          <li>
            Инфраструктура для работы. Как&nbsp;бы ни&nbsp;приятно было
            проводить время в&nbsp;собственной однокомнатной квартире, время
            от&nbsp;времени придется возвращаться к&nbsp;работе. Делать это
            можно, не&nbsp;покидая территорию комплекса. Для этого
            в&nbsp;распоряжении жильцов есть коворкинг-зона, где можно посетить
            с&nbsp;ноутбуком, назначить деловую встречу или организовать
            небольшое бизнес-мероприятие.
          </li>
        </ol>
        <h3 class="h4">
          Как купить квартиру в&nbsp;Москве у&nbsp;Ботанического сада
        </h3>
        <p>
          Благодаря низкой стоимости квартиры у&nbsp;Ботанического сада доступны
          всем желающим. Однако если покупатель не&nbsp;может или не&nbsp;желает
          заплатить всю сумму сразу, застройщик предлагает несколько
          дополнительных вариантов платежей:
        </p>
        <ul>
          <li>
            Льготная ипотека от&nbsp;банков-партнеров. Выплаты можно
            распределить на&nbsp;30&nbsp;лет и&nbsp;стать собственником
            недвижимости, внеся только&nbsp;10% от&nbsp;ее&nbsp;стоимости.
            Процент за&nbsp;пользование кредитом составляет от&nbsp;2,6%
            годовых.
          </li>
          <li>
            Ипотека Лайт. &laquo;Сбербанк&raquo; в&nbsp;сотрудничестве
            с&nbsp;ГК&nbsp;&laquo;Основа&raquo; предлагает уникальную программу
            ипотечного кредитования на&nbsp;покупку квартир у&nbsp;Ботанического
            сада. Благодаря особой схеме кредитования можно существенно
            сократить размер ежемесячного платежа.
          </li>
          <li>
            Рассрочка. Купить 1-комнатную квартиру в&nbsp;Москве
            у&nbsp;Ботанического сада можно, если заплатить&nbsp;20% стоимости
            при оформлении документов. Условия договора на&nbsp;приобретение
            жилья в&nbsp;рассрочку можно уточнить у&nbsp;менеджеров отдела
            продаж.
          </li>
          <li>
            Trade-In. Вариант для тех, кто хочет продать свою старую квартиру
            и&nbsp;стать собственником современного жилья в&nbsp;ЖК&nbsp;Very.
            Застройщик фиксирует стоимость недвижимости на&nbsp;день заключения
            договора и&nbsp;ожидает в&nbsp;течение месяца, пока покупатель
            не&nbsp;продаст свою старую квартиру и&nbsp;не&nbsp;оплатит
            оставшуюся сумму.
          </li>
        </ul>
        <p>
          Надоело тратить деньги на&nbsp;аренду недвижимости? Приняли решение
          обзавестись собственным жильем? Обратитесь к&nbsp;менеджерам
          по&nbsp;работе с&nbsp;клиентами по&nbsp;телефону или заполните форму
          на&nbsp;сайте.
        </p>
      </div>
    </template>
  </FlatsSearch>
</template>

<script>
import FlatsSearch from "@/components/FlatsSearch.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    FlatsSearch,
  },

  data() {
    return {
      costMin: null,
    };
  },
  methods: {
    onCostMinEmits(costMin) {
      this.costMin = costMin;
    },
  },
};
</script>
